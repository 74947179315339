export const zones = [
   
    { id: 2, name: "Riyadh", key: "Riyadh",  location: {
      latitude:'24.7136',
      longitude:'46.6753'
    } },
    { id: 3, name: "Jeddah", key: "Jeddah",location:{
      latitude:'24.7136',
      longitude:'46.6753'
    } },
    { id: 4, name: "Khobar", key: "Khobar",location:{
      latitude:'26.2171906',
      longitude:'50.1971381'
    }},
    { id: 4, name: "Dammam", key: "Dammam",location:{
      latitude:'26.406403017599292',
      longitude:'50.16973586527128'
    }},
    { id: 4, name: "Qatif", key: "Qatif",location:{
      latitude:'26.5765',
      longitude:'49.9982'
    }},
    { id: 5, name: "Madinah", key: "Madinah" ,location:{
      latitude:'24.4672',
      longitude:'39.6024'
    }},
    { id: 6, name: "Makkah", key: "Makkah" ,location:{
      latitude:'21.4241',
      longitude:'39.8173'
    }},
    { id: 7, name: "Al-Ahsa", key: "Al-Ahsa",location:{
      latitude:'21.9113',
      longitude:'49.3653'
    } },
    { id: 8, name: "Jubail", key: "Jubail",location:{
      latitude:'26.9598',
      longitude:'49.5687'
    } },
    { id: 9, name: "Tabuk", key: "Tabuk",location:{
      latitude:'28.3835',
      longitude:'36.5662'
    } },
    { id: 10, name: "Jazan", key: "Jazan",location:{
      latitude:'24.910753668856607',
      longitude:'46.62448153796469'
    } },
    { id: 11, name: "Asir", key: "Asir" ,location:{
      latitude:'19.0969',
      longitude:'42.8638'
    } },
    { id: 12, name: "Yanbu", key: "Yanbu" ,location:{
      latitude:'24.0895',
      longitude:'38.0618'
    } },
    { id: 13, name: "Al-Kharj", key: "Al-Kharj" ,location:{
      latitude:'24.1576',
      longitude:'47.3248'
    } },
    { id: 14, name: "Al-Majmaah", key: "Al-Majmaah",location:{
      latitude:'25.8759',
      longitude:'45.3731'
    }  },
    { id: 15, name: "Al-Qassim", key: "Al-Qassim",location:{
      latitude:'26.207826',
      longitude:'43.483738'
    }  },
    { id: 16, name: "Al-Quwayiyah", key: "Al-Quwayiyah",location:{
      latitude:'24.0671',
      longitude:'45.2806'
    }  },
    { id: 17, name: "Dumat Al-Jandal", key: "Dumat Al-Jandal" ,location:{
      latitude:'29.8108',
      longitude:'39.8888'
    } },
    { id: 18, name: "Hafar Al-Batin", key: "Hafar Al-Batin" ,location:{
      latitude:'28.3784',
      longitude:'45.9627'
    } },
    { id: 19, name: "Hail", key: "Hail" ,location:{
      latitude:'27.5114',
      longitude:'41.7208'
    } },
    { id: 20, name: "Hawtah Sudair", key: "Hawtah Sudair",location:{
      latitude:'25.5946',
      longitude:'45.6186'
    }  },
    { id: 21, name: "Taif", key: "Taif",location:{
      latitude:'21.2841',
      longitude:'40.4248'
    }  },
    { id: 22, name: "Najran", key: "Najran",location:{
      latitude:'17.5656',
      longitude:'44.2289'
    }  }
  ]


  export  const country =[
    { id: 1, name: "Saudi Arabia", },
    // { id: 2, name: "", key: "Play Zone!" },

  ]